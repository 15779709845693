
import { Component, Vue } from 'vue-property-decorator';
import OfferDetails from '@/components/offers/OfferDetails.vue';
import OfferDetailsHeader from '@/components/offers/OfferHeader.vue';

// Store
import { OfferModule } from '@/store/modules';
import { IStore, OfferDetail } from '@/types/offer.types';

@Component({
  components: { OfferDetails, OfferDetailsHeader }
})
export default class OfferDetailsPage extends Vue {
  offer: OfferDetail | undefined = new OfferDetail();

  title!: string;

  offerBackground = '';
  matchingValueType = '';

  created(): void {
    window.scrollTo(0, 0);

    if (localStorage.getItem('singleOffer')) {
      let anOffer: any = localStorage.getItem('singleOffer');
      let parsedOffer = JSON.parse(anOffer);

      if (parsedOffer.id === this.$route.query.id.toString()) {
        this.offer = parsedOffer;
      } else {
        this.getSingleOffer();
      }
    } else {
      this.getSingleOffer();
    }
  }

  get store(): IStore {
    if (this.offer?.storeDetails && this.offer.storeDetails?.length > 0)
      return this.offer?.storeDetails[0];
    return {};
  }

  get status(): string | undefined {
    // Active , Expired , Scheduled,  Archived
    if (this.offer?.offerState === 'Scheduled') return 'Coming Soon';
    return this.offer?.offerState;
  }

  getSingleOffer(): void {
    let AllOffers = OfferModule.getOffers;
    let sOffer = AllOffers.find(
      (item) => item.id === this.$route.query.id.toString()
    );

    this.offer = sOffer;
    localStorage.setItem('singleOffer', JSON.stringify(sOffer));
  }
}
